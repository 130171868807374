import React from "react";
import { GoLocation } from "react-icons/go";
import findusStyles from "./findus.module.css";

const Findus = () => {
  return (
    <>
      <h1 className={findusStyles.pageTitle}>
        Come <span className={findusStyles.textGreen}>find us</span> here
        <span className={findusStyles.textGreen}> !</span>
      </h1>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1287.6105531593632!2d55.213700035762976!3d25.115286750456033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x117b67ddc68283e6!2sMission%20Motorsport!5e1!3m2!1sen!2sae!4v1605127555498!5m2!1sen!2sae"
        frameBorder="0"
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
        className={findusStyles.googleMap}
        title="Mission Motorsport Google Maps Location"
      ></iframe>
      <div className={findusStyles.addressContainer}>
        <GoLocation className={findusStyles.addressIcon} />
        <h3 className={findusStyles.address}>Kanoo Group Warehouse 3 - 159</h3>
        <h3 className={findusStyles.address}>Umm Suqeim Street</h3>
        <h3 className={findusStyles.address}>Dubai, UAE</h3>
        <a className={findusStyles.phoneNumber} href="tel:044581491">
          04 458 1491
        </a>
      </div>
    </>
  );
};

export default Findus;
