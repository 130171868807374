import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Landing from "./Pages/Landing";
import WhatsApp from "./Components/WhatsApp";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  return (
    <>
      <Router>
        <Navbar />
        <WhatsApp />
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route path="/home" component={Landing} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
