import React from "react";
import Instagram from "../Components/Instagram";
import Findus from "./Findus";
import Services from "../Components/Services";

const Landing = () => {
  return (
    <>
      <Services />
      <Instagram />
      <Findus />
    </>
  );
};

export default Landing;
