import React from "react";

import navbarStyles from "./navbar.module.css";

const Navbar = () => {
  const logo = "./assets/logos/logo.png";
  return (
    <>
      <div className={navbarStyles.container}>
        <div className={navbarStyles.logoContainer}>
          <img src={logo} height="100%" alt="Mission Motorsport Logo" />
        </div>
      </div>
    </>
  );
};

export default Navbar;
