import React from "react";
import { FaWhatsapp } from "react-icons/fa";

import whatsappStyles from "./whatsapp.module.css";

const WhatsApp = () => {
  return (
    <>
      <a
        href="https://wa.me/+971555951778"
        className={whatsappStyles.whatsAppIcon}
      >
        <FaWhatsapp />
      </a>
    </>
  );
};

export default WhatsApp;
