import React from "react";
import servicesStyle from "./services.module.css";
import { Carousel } from "react-bootstrap";

const Services = () => {
  return (
    <>
      <h2 className={servicesStyle.pageTitle}>
        One stop shop for wheels, tires, brakes, suspension, regular service and
        maintenance
      </h2>
      <Carousel indicators={false}>
        <Carousel.Item>
          <img
            className={`d-block ${servicesStyle.icon}`}
            src="./assets/icons/wheel&tire.png"
            alt="Wheel and tire icon"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className={`d-block ${servicesStyle.icon}`}
            src="./assets/icons/suspension&brakes.png"
            alt="Suspension and brake icon"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className={`d-block ${servicesStyle.icon}`}
            src="./assets/icons/service.png"
            alt="service icon"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className={`d-block ${servicesStyle.icon}`}
            src="./assets/icons/tint.png"
            alt="Window tint icon"
          />
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default Services;
