import React from "react";

import instagramStyles from "./instagram.module.css";

const Instagram = () => {
  return (
    <div
      id="curator-feed-default-feed-layout"
      className={instagramStyles.instagramGrid}
    >
      <a
        href="https://curator.io"
        target="_blank"
        rel="noreferrer"
        className={`crt-logo crt-tag ${instagramStyles.curatorLink}`}
      >
        Powered by Curator.io
      </a>
    </div>
  );
};

export default Instagram;
